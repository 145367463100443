<div class="container" [ngClass]="show">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h1 class="view-h1">YouTube</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="embed-container">
        <iframe
          (load)="showYoutube()"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/videoseries?list=PLr8xDWug61muPDqdMOPoWm0VjQ_aNbrmM"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</div>

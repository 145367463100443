import {Component, OnInit, ElementRef, ViewChild, Inject} from '@angular/core';
// import fade in animation

/* import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; */
/* import { slideInDownFadeAnimation } from '../../assets/animations/index'; */
import {ResumeModalComponent} from './resume-modal/resume-modal.component';

@Component({
  selector: 'app-about', templateUrl: './about.component.html', styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {

  picLoaded = false;
  modalOpen = false;
  // tslint:disable-next-line:no-inferrable-types
  componentAnimate: string = '';
  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {}
  openModal() {
    this.modalOpen = true;
  }
  showComponent() {
    setTimeout(() => {
      this.componentAnimate = 'slideInDownFade';
    }, 300);
  }
  loadComponent() {
    this.picLoaded = true;
    this.showComponent();
  }
}

<div class="container" [ngClass]="show">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <h1 class="view-h1">Instagram</h1>
      </div>
    </div>

    <div class="ig-iframes">

      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-0" src="https://www.instagram.com/p/BG6jki6j00f/embed/?cr=1&amp;v=8&amp;wp=522#%7B%22ci%22%3A0%2C%22os%22%3A1561.4999999961583%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-0" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
          <script async="" defer="" src="//platform.instagram.com/en_US/embeds.js"></script>
        </div>
        <div class="xs-spacer-25 xs-clearFix"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-1" src="https://www.instagram.com/p/BG6j2Hdj01D/embed/?cr=1&amp;v=8&amp;wp=522#%7B%22ci%22%3A1%2C%22os%22%3A1565.999999991618%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-1" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
          <script async="" defer="" src="//platform.instagram.com/en_US/embeds.js"></script>
        </div>
        <div class="xs-spacer-25 xs-clearFix"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-2" src="https://www.instagram.com/p/BTWwapnBVq1/embed/?cr=1&amp;v=8&amp;wp=522#%7B%22ci%22%3A2%2C%22os%22%3A1571.299999995972%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-2" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
          <script async="" defer="" src="//platform.instagram.com/en_US/embeds.js"></script>
        </div>
        <div class="xs-spacer-25 xs-clearFix"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-3" src="https://www.instagram.com/p/BTWxXc8BLPq/embed/?cr=1&amp;v=8&amp;wp=522#%7B%22ci%22%3A3%2C%22os%22%3A4845.699999990757%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-3" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
          <script async="" defer="" src="//platform.instagram.com/en_US/embeds.js"></script>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-4" src="https://www.instagram.com/p/92J8SAj0-u/embed/?cr=1&amp;v=5&amp;wp=522#%7B%22ci%22%3A4%2C%22os%22%3A4862.999999997555%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-4" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
        </div>
        <div class="xs-spacer-25 xs-clearFix"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-5" src="https://www.instagram.com/p/7RT0O8j08w/embed/?cr=1&amp;v=5&amp;wp=522#%7B%22ci%22%3A5%2C%22os%22%3A4873.699999996461%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-5" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
        </div>
        <div class="xs-spacer-25 xs-clearFix"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-6" src="https://www.instagram.com/p/3XWcfHj07A/embed/?cr=1&amp;v=5&amp;wp=522#%7B%22ci%22%3A6%2C%22os%22%3A9151.299999997718%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-6" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
        </div>
        <div class="xs-spacer-25 xs-clearFix"></div>
        <div class="col-md-3 col-sm-6 col-xs-12">
          <iframe (load)="incrementLoadCount()" class="instagram-media instagram-media-rendered" id="instagram-embed-7" src="https://www.instagram.com/p/3XS1rjD00q/embed/?cr=1&amp;v=5&amp;wp=522#%7B%22ci%22%3A7%2C%22os%22%3A9205.099999991944%7D"
           allowtransparency="true" frameborder="0" height="323" data-instgrm-payload-id="instagram-media-payload-7" scrolling="no"
           style="background: rgb(255, 255, 255); border: 1px solid rgb(219, 219, 219); margin: 1px 1px 12px; max-width: 658px; width: calc(100% - 2px); border-radius: 4px; box-shadow: none; display: block; padding: 0px;"></iframe>
        </div>
      </div>
    </div>
  </div>

<div class="container">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">


      <h1 class="view-h1">SoundCloud</h1>

      <iframe (load)="showSoundcloud()" width="100%" height="300" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/165564515&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>

    </div>
  </div>
</div>

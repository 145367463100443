<app-resume-modal *ngIf="modalOpen === true" [state]="modalOpen" (toggle)="modalOpen = $event"></app-resume-modal>
<app-loader *ngIf="picLoaded === false" (contentLoad)="picLoaded = $event"></app-loader>
<div class="wrapper">
  <div class="bg-content about-us" [ngClass]="componentAnimate">
    <div class="content-extra" id="content">
      <div class="container">
        <div class="row">
          <div class="col-md-8 offset-md-2 col-sm-12 col-xs-12">
            <img (load)="loadComponent()" class="img-fluid img-center ced-portrait"
              alt="Cedric D.T. Jackson, Jr. of CJMedia, LLC" src="../../../assets/img/portrait/cedric-jackson.jpg" />
            <h2>About the Founder</h2>
            <p class="">
              Cedric D.T. Jackson, Jr. is a native of Cleveland Heights, Ohio,
              graduating from
              <a href="https://www.chuh.org/clevelandheightshighschool_home.aspx" target="_blank">Cleveland Heights High
                School</a>
              as one of its Commencement speakers, and earning his Bachelor of
              Science degree in Computer Information Systems from
              <a href="https://jcu.edu/" target="_blank">John Carroll University</a>.
            </p>
            <p>
              Cedric founded <b>CJMedia, LLC</b> in 2010 to provide professional
              <a href="https://cedricmedia.com/design" target="_blank">Web and Graphic design</a>, as well as
              <a href="https://cedricmedia.com/music" target="_blank">music services</a>.
            </p>
            <p>
              Cedric currently works as a
              <b>Senior Video Software Engineer</b> for
              <a href="https://dmedmedia.disney.com/" target="_blank">Disney Media and Entertainment Distribution
                (DMED)</a>, collaborating with a world-class team of engineers to build out
              the next generation video players and features. With a current
              focus on the video player and business rules for Interstitial
              interactions. Cedric writes code that makes the
              <a href="https://www.disneyplus.com/home" target="_blank">Disney Plus</a>
              app work on living room devices, such as TVs (brands like
              <a href="https://www.lg.com/us">LG</a>,
              <a href="https://www.samsung.com/us/">Samsung</a>,
              <a href="https://hisense.com/">Hisense</a>,
              <a href="https://na.panasonic.com/us/">Panasonic</a>, etc.),
              <a href="https://store.google.com/us/product/chromecast_google_tv?pli=1&hl=en-US"
                target="_blank">Chromecast</a>
              and
              <a href="https://en.wikipedia.org/wiki/Set-top_box" target="_blank">set-top boxes</a>. He also
              co-architects new player features, collaborates with
              Client application development teams, Services engineering, and QA
              teams and product to help understand and explain device
              limitations and requirements.
            </p>

            <p>
              Cedric is also a professional Multi-instrumentalist. At the age of
              3, Cedric began to play the drumset. At the age of 13, his strong
              love for music motivated him to pick up the electric bass and
              piano. In later years, he would pick up the Hammond organ.
            </p>

            <p>
              Cedric's prowess at making music has granted him amazing
              opportunities. Of his musical accomplishments, he deems
              accompanying the eight-time Grammy and Academy Award winning
              Gospel artist,
              <a href="https://en.wikipedia.org/wiki/Andra%C3%A9_Crouch">Pastor Andraé Crouch</a>, on piano as one of
              his greatest.
            </p>
            <p>
              Cedric has also played behind, composed, arranged, and/or produced
              music for Christian and Gospel artists such as
              <a href="https://en.wikipedia.org/wiki/John_P._Kee" target="_blank">John P. Kee</a>,
              <a href="https://www.youtube.com/results?search_query=Durward+davis&sp=CAM%253D" target="_blank">Durward
                Davis</a>,
              <a href="https://www.youtube.com/results?search_query=isaiah+templeton&sp=CAM%253D" target="_blank">Isaiah
                Templeton</a>,
              <a href="https://www.youtube.com/results?search_query=Geoffrey+Golden&sp=CAM%253D"
                target="_blank">Geoffrey Golden</a>,
              <a href="https://www.amantelaceyworldwide.com/" target="_blank">Amante Lacy</a>,
              <a href="https://music.apple.com/us/artist/daniel-johnson/27370654" target="_blank">Daniel Johnson</a>,
              <a href="https://faichurch.org/our-pastor/" target="_blank">Jonathan Dunn</a>, and others.
            </p>
            <p>Cedric currently serves as Lead Musician at <a href="https://rhemafellowshipchurch.com/"
                target="_blank">Rhema Fellowship Church</a>, where <a
                href="https://www.linkedin.com/in/rev-anthony-t-parker-esq-90329a2a" target="_blank">Pastor Anthony
                T. Parker</a> is pastor.</p>
            <p>
              ***To connect with Cedric, visit the
              <b> <a routerLink="../contact/">Contact page</a> </b>.
            </p>
            <h3>Resume</h3>
            <p>
              <b>
                <a id="CedricResume" (click)="openModal()">Click here</a>
              </b>
              to view Cedric Jackson, Jr.'s LinkedIn profile.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
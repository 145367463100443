import { Component, OnInit, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [

  ]
})
export class HeaderComponent implements OnInit {
  isActive = false;
  open = false;
  constructor() { }

  ngOnInit() {
  }
  toggleMenu() {
    this.open = !this.open;
  }
  makeActive() {
    this.open = false;
  }


}

<app-loader *ngIf="everythingReady === false"></app-loader>
<div class="bg-content music" [ngClass]="componentAnimate">
  <!--============================== content =================================-->
  <div class="content-extra" id="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <h2 class="">Music</h2>
          <p>
            <b>CJMedia, LLC</b> provides industry-grade music services.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="table sears-protect-table">
                <div class="results-table">
                  <h2>Music Services</h2>
                  <ul class="result-row planSearchResult">
                    <li class="coverages">
                      <label class="hidden-label">Live Performance</label>
                      <span>Live Performance</span>
                    </li>
                    <li>
                      <label class="hidden-label">Live Performance</label>
                      <span>
                        <span class="all-instruments"><b>All instruments:</b></span>
                        Indoor/Outdoor events for ministries, special causes,
                        and corporate events.
                      </span>
                    </li>
                  </ul>
                  <ul class="result-row planSearchResult">
                    <li class="coverages">
                      <label class="hidden-label">Composition</label>
                      <span>Composition and Recording</span>
                    </li>
                    <li>
                      <span>
                        <span class="all-instruments"><b>All instruments:</b></span>
                        Christian/Gospel recordings, TV and radio jingles</span>
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                <span class="all-instruments"><b>All instruments:</b></span>
                piano, drum set, bass guitar, and Hammond B3 organ.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="youtube-wrap">
      <app-youtube [(getYoutubeStatus)]="isYoutubeReady"></app-youtube>
    </div>
    <div class="soundcloud-wrap">
      <app-soundcloud [(getSoundCloudStatus)]="isSoundCloudReady"></app-soundcloud>
    </div>
    <div class="ig-wrap">
      <app-ig [(getIGStatus)]="isIGReady"></app-ig>
    </div>
  </div>
</div>
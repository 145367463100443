import { Component, OnInit } from '@angular/core';
// import fade in animation
/* import { slideInLeftFadeAnimation } from '../../assets/animations/index'; */

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss'],

  // make fade in animation available to this component
  /* animations: [slideInLeftFadeAnimation], */

  // attach the fade in animation to the host (root) element of this component
  // tslint:disable-next-line:use-host-property-decorator
  /* host: { '[@slideInLeftFadeAnimation]': '' } */
})
export class DesignComponent implements OnInit {
  // tslint:disable-next-line:no-inferrable-types
  componentAnimate: string = '';
  constructor() {
    window.scrollTo(0, 0);
    this.showComponent();
  }

  ngOnInit() {
  }
  showComponent() {
    setTimeout(() => {
      this.componentAnimate = 'slideInLeftFade';
    }, 100);
  }
}

import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})

export class ModalComponent implements OnInit {
  // tslint:disable-next-line:no-inferrable-types
  className: string = '';
  constructor() {
    setTimeout(() => {
      this.className = 'animate-modal';
    }, 800);
  }

  ngOnInit() {
  }
}

<div class="loader-wrap">
  <div class="lds-css ng-scope loaderGif">
    <div style="width:100%;height:100%" class="lds-wedges">
      <div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-indicator" [ngClass]="animateMessage">{{loaderMessages}}</div>

</div>

import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {AboutComponent} from '../../about/about.component';

@Component({selector: 'app-loader', templateUrl: './loader.component.html', styleUrls: ['./loader.component.scss']})
export class LoaderComponent implements OnInit {

  slideNum = 1;
  minSlideNum = 1;
  maxSlideNum = 3;
  loaderMessages;
  loopPres;
  animateMessage;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.runSlideShow();
  }
  runSlideShow() {
    this.loopPres = setInterval(() => {

      switch (this.slideNum) {

          // loop through messages based on value of slidenum
        case 0:
          clearInterval(this.loopPres);
          break;
        case 1:
          this.animateMessage = 'fadeInLeft';
          this.loaderMessages = 'Hey...just a few more secs.';
          setTimeout(() => {
            this.animateMessage = '';
          }, 3500);
          break;
        case 2:
          this.animateMessage = 'fadeInLeft';
          this.loaderMessages = 'Almost there...';
          setTimeout(() => {
            this.animateMessage = '';
          }, 3500);
          break;
        case 3:
          this.animateMessage = 'fadeInLeft';
          this.loaderMessages = 'Get ready...';
          setTimeout(() => {
            this.animateMessage = '';
          }, 3500);
          break;
      }
      if (this.slideNum === this.maxSlideNum) {
        // if we are currently at the last slide, go to the first slide
        this.slideNum = this.minSlideNum;
      } else {
        // increment the count
        this.slideNum++;
        if (this.slideNum > this.minSlideNum) {}
      }
    }, 4000);
  }
}

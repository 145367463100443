<nav class="desktop-nav">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-xs-12 xs-no-p-l-r">
				<div class="nr-wrapper">
					<ul class="nav-links">
						<li routerLink="../../home" routerLinkActive="active">Home
						</li>
						<li routerLink="../../about" routerLinkActive="active">About
						</li>
						<li routerLink="../../design" routerLinkActive="active">Design
						</li>
						<li routerLink="../../music" routerLinkActive="active">Music
						</li>
						<li routerLink="../../contact" routerLinkActive="active">Contact
						</li>
					</ul>
				</div>
					<!-- <img src="../../../../assets/img/logo/logo.png" class="img-responsive" alt=""> -->
          <app-logo routerLink="../../home" routerLinkActive="active"></app-logo>
        
				<!--/.nav-collapse -->
			</div>
		</div>
	</div>
</nav>
<nav class="m-nav" [ngClass]="{'expand-m-nav': open}">
  <ul class="list-unstyled main-menu">
	<!--Include your navigation here-->
	<li class="text-right"><a id="nav-close" (click)="toggleMenu()">X</a></li>
	<li routerLink="../../home" (click)="makeActive()" routerLinkActive="active">Home</li>
	<li routerLink="../../about" (click)="makeActive()" routerLinkActive="active">About</li>
	<li routerLink="../../design" (click)="makeActive()" routerLinkActive="active">Design</li>
	<li routerLink="../../music" (click)="makeActive()" routerLinkActive="active">Music</li>
	<li routerLink="../../contact" (click)="makeActive()" routerLinkActive="active">Contact</li>
  </ul>
</nav>
<div class="navbar navbar-inverse fixed-top m-nav-trigger">
  <!--Include your brand here-->
  <app-logo routerLink="../../home" routerLinkActive="active"></app-logo>
  <div class="navbar-header pull-right">
	<a id="slide-nav-expander" class="slide-nav-expander fixed" (click)="toggleMenu()">

	</a>
  </div>
</div>
<span class="slide-mask" *ngIf="open === true"></span>

<div class="home" [ngClass]="componentAnimate">
  <div class="slide">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12">
          <span class="slide-text-small top-s-text" [ngClass]="experience"
            >Experience . . .
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
          <span class="ced-slide">
            <span class="slide-text-big" [ngClass]="cedtalks"
              >CED
              <span class="blue-text">Talks</span>
            </span>
            <span class="slide-text-small bottom-s-text" [ngClass]="slogan"
              >Past experiences.
              <span class="bottom-reg-text xs-nowrap">Current status</span>.
              <span class="xs-nowrap">valuable guidance.</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-content bg-lg-height">
    <div class="container">
      <div class="row">
        <div class="col-md-10 col-offset-md-1 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <h2>Welcome</h2>
            </div>
            <div
              class="col-md-8 col-sm-8 col-xs-12 vertical-r xs-no-vertical-r"
            >
              <p>
                <b>CJMedia, LLC</b> is a website construction, graphic art
                design and music production company.
              </p>
              <p>
                We use our client's input to create eye-catching designs that
                enhance brand quality. <b>CJMedia, LLC</b> also offers a wide
                variety of music services tailored specifically to you.
              </p>
              <p>
                From graphic arts design to music production,
                <b>CJMedia, LLC</b> is your source for dynamic media production.
              </p>
              <p>
                Visit our <a routerLink="../contact/">Contact Page</a> and
                submit your design inquiries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {
  @Input() getYoutubeStatus: boolean;
  @Output() getYoutubeStatusChange = new EventEmitter<boolean>();
  youtubeLoaded = false;

  // tslint:disable-next-line:no-inferrable-types
  countIframesLoaded: number = 0;
  show;
  constructor() { }

  ngOnInit() {
  }
  showYoutube() {
    this.youtubeLoaded = true;
    this.getYoutubeStatusChange.emit(true);
    this.show = 'show-social';
  }
  incrementLoadCount() {
    this.countIframesLoaded++;
    if (this.countIframesLoaded === 1) {
      this.showYoutube();
    }
  }
}

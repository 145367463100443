import { Component, OnInit } from '@angular/core';
import { SoundcloudComponent } from './soundcloud/soundcloud.component';
// import fade in animation
/* import { slideInDownFadeAnimation } from '../../assets/animations/index'; */

@Component({
  selector: 'app-music',
  templateUrl: './music.component.html',
  styleUrls: ['./music.component.scss'],

  // make fade in animation available to this component
  /* animations: [slideInDownFadeAnimation], */

  // attach the fade in animation to the host (root) element of this component
  // tslint:disable-next-line:use-host-property-decorator
  /*  host: { '[@slideInDownFadeAnimation]': '' } */
})
export class MusicComponent implements OnInit {
  message;

  isYoutubeReady: boolean;
  isSoundCloudReady: boolean;
  isIGReady: boolean;
  everythingReady = false;

  truthCount = 0;
  // tslint:disable-next-line:no-inferrable-types
  componentAnimate: string = '';

  constructor() {
    window.scrollTo(0, 0);
  }
  ngOnInit() {
  }

  socialTest() {
    return this.isSoundCloudReady === true && this.isYoutubeReady === true && this.isIGReady === true;
  }

  showComponent() {
    setTimeout(() => {
      this.componentAnimate = 'slideInDownFade';
    }, 300);
  }

  ngAfterViewInit() {
    if (!this.socialTest) {
      return;
    }

    this.everythingReady = true;
    this.showComponent();

  }
}

import {Component, OnInit} from '@angular/core';

@Component({selector: 'app-logo', templateUrl: './logo.component.html', styleUrls: ['./logo.component.scss']})
export class LogoComponent implements OnInit {
  logoFadeIn;
  logoJack;
  letterFadeIn;
  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
    this.loadLogo();
  }, 1000);
  }
  loadLogo() {
    setTimeout(() => {
      this.logoFadeIn = 'fadeIn';
      this.logoJack = 'jackInTheBox animated';
    }, 300);
    setTimeout(() => {
      this.letterFadeIn = 'fadeIn animated';
    }, 1500);
  }
}

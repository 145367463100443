import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  stillLoading = true;
  title = 'app';

  constructor() {

  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.stillLoading = false;
  }

}

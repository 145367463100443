import { Component, OnInit } from '@angular/core';


// import fade in animation
/* import { slideInRightFadeAnimation } from '../../assets/animations/index'; */

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],

 // make fade in animation available to this component
/*  animations: [slideInRightFadeAnimation], */

 // attach the fade in animation to the host (root) element of this component
 // tslint:disable-next-line:use-host-property-decorator
 /* host: { '[@slideInRightFadeAnimation]': '' } */
})
export class ContactComponent implements OnInit {

  // tslint:disable-next-line:no-inferrable-types
  componentAnimate: string = '';
  constructor() {
    window.scrollTo(0, 0);
    this.showComponent();
  }

  ngOnInit() {
  }
  showComponent() {
    setTimeout(() => {
      this.componentAnimate = 'slideInRightFade';
    }, 100);
  }

}

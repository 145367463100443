import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AboutComponent} from '../about.component';

@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styleUrls: ['./resume-modal.component.scss']
})
export class ResumeModalComponent implements OnInit {
  @Input() state: boolean;
  @Output() toggle = new EventEmitter();
  // tslint:disable-next-line:no-inferrable-types
  maskAnimate: string = '';
  // tslint:disable-next-line:no-inferrable-types
  modal: string = '';
  constructor() {
    setTimeout(() => {
      this.maskAnimate = 'maskAnimate';
      this.modal = 'animate-modal';
    }, 100);
  }
  closeModal() {

    this.maskAnimate = '';
    this.modal = '';
    setTimeout(() => {
      this.state = false;
      this.toggle.emit(this.state);
    }, 200);
  }
  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

// import fade in animation
/* import { fadeInAnimation } from '../../assets/animations/index'; */


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],


  // make fade in animation available to this component
  /* animations: [fadeInAnimation], */

  // attach the fade in animation to the host (root) element of this component
  // tslint:disable-next-line:use-host-property-decorator
  /* host: { '[@fadeInAnimation]': '' } */
})
export class HomeComponent implements OnInit {
  experience;
  cedtalks;
  slogan;

  // tslint:disable-next-line:no-inferrable-types
  componentAnimate: string = '';
  constructor() {
    window.scrollTo(0, 0);
    this.showComponent();
  }

  ngOnInit() {
    setTimeout(() => {
      this.loadCedTalks();
    }, 1000);
  }
  showComponent() {
    setTimeout(() => {
      this.componentAnimate = 'fadeIn';
    }, 100);
  }
  loadCedTalks() {
    setTimeout(() => {
      this.experience = 'bounceInLeft animated';
      this.cedtalks = 'bounceInDown animated';
      this.slogan = 'fadeInUp animated';
    }, 700);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRoutes } from '@angular/router';
import { routes } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/modules/header/header.component';
import { ContentComponent } from './components/modules/content/content.component';
import { FooterComponent } from './components/modules/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { DesignComponent } from './components/design/design.component';
import { MusicComponent } from './components/music/music.component';
import { ContactComponent } from './components/contact/contact.component';
import { ModalComponent } from './components/modules/modal/modal.component';
import { ResumeModalComponent } from './components/about/resume-modal/resume-modal.component';
import { SoundcloudComponent } from './components/music/soundcloud/soundcloud.component';
import { LoaderComponent } from './components/modules/loader/loader.component';
import { IgComponent } from './components/music/ig/ig.component';
import { YoutubeComponent } from './components/music/youtube/youtube.component';
import { LogoComponent } from './components/modules/logo/logo.component';

@NgModule({
  imports: [
    BrowserModule,
    routes,
    BrowserAnimationsModule
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    DesignComponent,
    MusicComponent,
    ContactComponent,
    ModalComponent,
    ResumeModalComponent,
    SoundcloudComponent,
    LoaderComponent,
    IgComponent,
    YoutubeComponent,
    LogoComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { DesignComponent } from './components/design/design.component';
import { MusicComponent } from './components/music/music.component';
import { ContactComponent } from './components/contact/contact.component';
import { ModalComponent } from './components/modules/modal/modal.component';

export const router: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home - CJMedia, LLC' }
  }, {
    path: 'about',
    component: AboutComponent,
    data: { title: 'About - CJMedia, LLC' }
  }, {
    path: 'design',
    component: DesignComponent,
    data: { title: 'Design - CJMedia, LLC' }
  }, {
    path: 'music',
    component: MusicComponent,
    data: { title: 'Music - CJMedia, LLC' }
  }, {
    path: 'contact',
    component: ContactComponent,
    data: { title: 'Contact - CJMedia, LLC' }
  },
  {
    path: 'resume',
    component: ModalComponent,
    data: { title: 'Resume - CJMedia, LLC' }
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

export const routes: ModuleWithProviders<any> = RouterModule.forRoot(router);

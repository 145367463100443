import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';

@Component({selector: 'app-ig', templateUrl: './ig.component.html', styleUrls: ['./ig.component.scss']})
export class IgComponent implements OnInit {
  @Input() getIGStatus: boolean;
  @Output() getIGStatusChange = new EventEmitter<boolean>();

  show;

  // tslint:disable-next-line:no-inferrable-types
  countIframesLoaded: number = 0;
  constructor() {}

  ngOnInit() {}
  showIG() {
    this.getIGStatus = true;
    this.getIGStatusChange.emit(true);
    this.show = 'show-social';
  }
  incrementLoadCount() {
    this.countIframesLoaded++;
    if (this.countIframesLoaded === 8) {
      this.showIG();
    }
  }
}

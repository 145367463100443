<div id="myModal" class="modal" [ngClass]="maskAnimate">
  <!-- Modal content -->
  <div class="modal-content" [ngClass]="modal">
    <!-- <span class="close">&times;</span> -->
    <div class="row">
      <div class="col-md-12">
        <h1>Hello there...</h1>
        <p>
          You are about to leave
          <a href="cedricmedia.com" target="_blank">cedricmedia.com</a> , and
          navigate to Cedric's LinkedIn profile.
        </p>
      </div>
    </div>
  </div>
  <div class="c-c-wrap" [ngClass]="modal">
    <button class="link cancel" (click)="closeModal()">No, Thanks</button>
    <a
      class="link download"
      (click)="closeModal()"
      href="https://www.linkedin.com/in/cedricmedia/"
      target="_blank"
      >Cedric's LinkedIn</a
    >
  </div>
</div>

<svg
  id="Layer_1"
  class="cedricmedia-logo"
  [ngClass]="logoFadeIn"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 1982 490"
>
  <g [ngClass]="logoJack" class="c-name-letter">
    <path
      d="M777.53,10.99l-94.75,163.3L588.03,10.99c-96.85,37.44-165.47,130.52-165.47,239.48,0,99.51,57.23,185.82,140.94,228.55l53.11-91.54c-50.87-24.61-85.95-76.71-85.95-137.01,0-36.01,12.56-69.06,33.48-95.1l118.65,201.83,118.65-201.83c20.92,26.05,33.48,59.09,33.48,95.1,0,60.3-35.09,112.4-85.95,137.01l53.11,91.54c83.71-42.74,140.94-129.04,140.94-228.55,0-108.96-68.62-202.04-165.47-239.48Z"
      style="fill: #2b67b2; fill-rule: evenodd"
    />
    <g>
      <path
        d="M398.67,344.1c-40.62,60.92-119.15,100.09-201.07,81.38-68.34-15.61-121.85-69.52-137.07-137.95C33.6,166.51,124.95,59.38,241.32,59.38c66.32,0,129.38,34.14,162.19,86.47,5.24-11.1,13.09-27.19,25.49-44.01C376.49,35.13,285.06-4.09,190.04,16.06,99.68,35.21,29.37,106.95,11.65,197.61c-29.36,150.15,84.8,281.63,229.68,281.63,72.38,0,139.86-35.05,182.85-86.62-10.1-14.69-18.86-31.96-25.51-48.52Z"
        style="fill-rule: evenodd"
      />
      <path
        d="M313.51,71.26l1.55,196.11c0,39.8-32.26,72.06-72.06,72.06s-72.06-32.26-72.06-72.06h-58.38c-.03,1.09-.04,2.19-.04,3.28,0,70.84,58.35,128.05,129.6,126.19,68.61-1.78,122.87-58.9,122.87-127.53V71.26h-51.49Z"
        style="fill: #231f20"
      />
    </g>
  </g>
  <g class="c-name-letter" [ngClass]="letterFadeIn">
    <path
      d="M964.41,270.38c0-70.02,49.23-128.76,128.65-128.76s126.79,59.68,126.79,126.41c0,7.99-.46,15.98-1.39,21.15h-188.09c3.25,38.53,32.05,61.56,64.56,61.56,24.61,0,49.69-13.16,57.59-33.37l53.41,15.04c-17.65,37.59-59.45,64.38-113.32,64.38-78.49,0-128.18-58.27-128.18-126.41Zm191.34-22.09c-3.25-36.66-29.72-61.09-63.63-61.09s-59.91,24.44-62.7,61.09h126.32Z"
      style="fill: #231f20"
    />
    <path
      d="M1267.67,268.97c0-70.96,45.05-127.35,108.68-127.35,35.3,0,65.95,19.74,81.74,47.46V49.03h62.23V319.72c0,13.16,4.64,18.33,16.72,18.8v53.57c-12.54,2.35-22.29,3.29-29.72,3.29-22.76-.47-35.76-11.28-38.55-29.14l-1.39-16.92c-17.65,31.02-50.62,47.46-84.53,47.46-67.34,0-115.18-56.39-115.18-127.82Zm190.88,32.9v-58.74c-10.22-27.26-37.62-47.93-63.16-47.93-37.62,0-63.63,35.72-63.63,75.19s28.79,72.84,67.34,72.84c23.69,0,50.62-17.39,59.45-41.35Z"
      style="fill: #231f20"
    />
    <path
      d="M1607.63,111.54V49.03h62.23v62.5h-62.23Zm0,280.55V145.84h62.23v246.25h-62.23Z"
      style="fill: #231f20"
    />
    <path
      d="M1733.02,318.78c0-46.99,42.73-79.42,103.57-79.42,21.83,0,43.66,3.76,59.45,10.81v-14.1c0-32.43-18.58-50.75-54.34-50.75-26.01,0-50.16,9.4-76.17,27.73l-19.04-39.94c31.58-21.15,65.02-31.49,101.25-31.49,69.66,0,110.53,36.19,110.53,101.51v76.6c0,13.16,4.64,18.33,16.72,18.8v53.57c-11.61,2.35-22.29,3.29-29.72,3.29-23.22-.47-35.3-11.75-38.55-29.14l-1.39-13.63c-21.83,28.67-53.87,44.17-89.17,44.17-47.37,0-83.13-33.37-83.13-78.01Zm152.8,11.28c6.5-5.17,10.22-11.75,10.22-17.86v-28.2c-14.86-5.64-33.44-9.4-49.23-9.4-32.05,0-54.8,16.45-54.8,39,0,21.15,18.11,37.13,41.8,37.13,20.9,0,41.33-7.99,52.02-20.68Z"
      style="fill: #231f20"
    />
  </g>
</svg>

<footer>
  <div class="container clearfix">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <div class="footer-content">
          <div class="privacy">
            <p>
              Website Design by CJMedia, LLC&trade;<br />
              <a href="http://cedricmedia.com/website-terms" target="_self" title="Terms Of Service/Privacy">Terms Of
                Service/Privacy</a>
            </p>
          </div>
          <div class="cm-social-icons-row">
            <a href="mailto:cedriccjmusic@gmail.com" target="_blank">
              <div class="icon socicon-mail"></div>
            </a>
            <a href="https://www.linkedin.com/in/cedric-jackson-jr" target="_blank">
              <div class="icon socicon-linkedin"></div>
            </a>
            <a href="https://instagram.com/cedric.jackson.jr/" target="_blank">
              <div class="icon socicon-instagram"></div>
            </a>
            <a href="https://www.youtube.com/@cjmedia_llc" target="_blank">
              <div class="icon socicon-youtube"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
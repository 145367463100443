<div class="cm-contact-icon-wrap contact-us" [ngClass]="componentAnimate">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 col-xs-12">
        <h1 class="connect-h1">Connect with <b>CJMedia, LLC</b></h1>
        <p class="connect-p">
          Don't be a stranger. This is your invitation to connect with
          <b>CJMedia, LLC</b> via the interwebs.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 text-center">
        <a href="mailto:cedriccjmusic@gmail.com" class="social-link">
          <span class="cm-contact-icon-sprite email" style="margin-top: 15px"></span>
        </a>

        <a href="https://www.linkedin.com/in/cedric-jackson-jr/" class="social-link">
          <span class="cm-contact-icon-sprite linkedIn" style="margin-top: 15px"></span>
        </a>

        <a href="https://instagram.com/cedric.jackson.jr/" class="social-link">
          <span class="cm-contact-icon-sprite ig" style="margin-top: 15px"></span>
        </a>

        <a href="https://www.youtube.com/@cjmedia_llc" class="social-link">
          <span class="cm-contact-icon-sprite youT"></span>
        </a>
      </div>
    </div>
  </div>
</div>
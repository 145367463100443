<div class="bg-content design" [ngClass]="componentAnimate">
  <!--============================== content =================================-->

  <div class="content-extra" id="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 col-xs-12">
          <h2 class="">Design</h2>
          <p class="p-border">
            <b>CJMedia, LLC</b> has amassed an expansive portfolio of visually-appealing designs for print and digital
            mediums.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center">
          <a class="view-reach" target="_blank"
            href="https://www.behance.net/gallery/16767481/CEDRICMedia-Graphic-Design-Portfolio">View The Portfolio</a>
          <a class="view-reach" routerLink="../contact/">Connect with Cedric</a>
        </div>
      </div>
    </div>
  </div>
</div>